import React from 'react'
import {
    Container,
    Box,
    Text,
    Heading,
    Flex
} from '@chakra-ui/react'

import { graphql, useStaticQuery } from 'gatsby'

import IconBlock from './IconBlock'

const OurServicesBlock = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicHomepageContent {
        edges {
          node {
            data {
              our_services_section {
                description {
                  text
                }
                icon {
                  localFile {
                    publicURL
                  }
                }
                learn_more_link {
                  slug
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

const document = data.allPrismicHomepageContent.edges[0].node.data.our_services_section
    return (
        <Container maxWidth="container.xl" my={20} p={8}>
            <Box w={{sm: '100%', md: '100%'}} textAlign={{sm: 'center', md: 'left'}}>
            <Text variant="sub-head">
                Our Services
            </Text>
            <Heading as="h2" fontWeight="600">
                We Provide Neurotechnology for Advanced Medical Applications
            </Heading>
            </Box>
            <Box my={10}>
                <Flex flexWrap="wrap">
                    {document.map((service) => {
                        return (
                            <IconBlock 
                                icon={service.icon.localFile.publicURL}
                                heading={service.title.text}
                                text={service.description.text}
                                linkTo="/services"
                            />
                        )
                    })}
                {/* <IconBlock 
                        icon={<DesignIcon />}
                        heading="Design"
                        text="Our experienced engineering team and in-house clean-room facilities allow for rapid design development."
                        linkTo="/services"
                    />
                            <IconBlock 
                        icon={<VerificationIcon />}
                        heading="Verification"
                        text="Our engineering team is thoroughly versed in medical device design standards, including for active implantable devices, and offers a full range of design verification services."
                        linkTo="/services"
                    />
                    <IconBlock 
                        icon={<ManufacturingIcon width="40px" />}
                        heading="Manufacturing"
                        text="Our experienced engineering team and in-house clean-room facilities allow for rapid design development."
                        linkTo="/services"
                    />
                            <IconBlock 
                        icon={<LicensingIcon width="30px" />}
                        heading="Licensing"
                        text="Our experienced engineering team and in-house clean-room facilities allow for rapid design development."
                        linkTo="/services"
                    /> */}
                </Flex>
            </Box>
            
        </Container>
    )
}

export default OurServicesBlock
