import React from 'react'
import Carousel from 'react-multi-carousel'
import "react-multi-carousel/lib/styles.css";
import { FaChevronRight } from 'react-icons/fa'
import { Box, Text, Button } from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'


const ImageGridItem = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
`;

const TextItem = styled(Box)`
    position: absolute;
    width: 100%;
    text-align: center;
    
    h3 {
       font-size: 2rem;
       font-weight: 600;
       line-height: 2rem;
       margin-bottom: .8rem;
    }
    .member-title {
        font-family: 'Open Sans', sans-serif;
        color: #eab117;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
    }
`

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      gap: 10
      //slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

// const PropertyBox = styled.div`
//   text-align: center;
//   padding: 4rem;
//   background-color: ${props => props.bg};
// `;

export const ImageItem = (props) => (
    <TextItem>
        <Text as="h3" fontSize={{ sm: '1.75rem', md: '2rem', lg: '2.25rem'}}>
            {props.memberName}
        </Text>
        <Text className="member-title">
            {props.memberTitle}
        </Text>
        <div style={{
            borderBottom: '2px solid white',
            width: '10rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '1rem',
            marginBottom: '1rem',

        }}/>
        <a href={props.listingLink}>
            <Button variant="ghost"
                color="pine.200"
                textTransform="uppercase"
                fontSize="1.25rem"
                _hover={{
                    backgroundColor: 'none',
                    color: 'white'
                }}>
                Book Now <FaChevronRight />
            </Button>
            </a>

    </TextItem>
)

const PropertiesCarousel = (props) => {

    return (
        <Box ml="-4">
        <Carousel
            swipeable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            //containerClass="carousel-container"
            //removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
        <Box
            ml="4"
        >
            <ImageGridItem w="100%" h="100%">
                <StaticImage 
                    src="../../../images/slide1.jpg"
                    placeholder="blurred"
                    layout="fullWidth"
                />
          {/* <img
                    className="friend" 
                    src={Slide1}
                    alt="Slide 1"
                /> */}
{/*                 
                <ImageItem 
            memberName={<>Urban Flat <br />In Santa Rosa</>}
            listingLink="https://www.airbnb.com/rooms/29578859?source_impression_id=p3_1624919097_FkS5aJ0qre7KLuzS"
            
        /> */}
            </ImageGridItem>             
        </Box>
        <Box
           ml="4" 
        >
              <ImageGridItem w="100%" h="100%">
              <StaticImage 
                    src="../../../images/woman-assembling.jpg"
                    placeholder="blurred"
                    layout="fullWidth"
                />
{/*                 
                <ImageItem 
            memberName={<>Urban Flat <br />In Santa Rosa</>}
            listingLink="https://www.airbnb.com/rooms/29578859?source_impression_id=p3_1624919097_FkS5aJ0qre7KLuzS"
            
        /> */}
            </ImageGridItem>     
        </Box>
        <Box
          ml="4"  
        >
             <ImageGridItem w="100%" h="100%">
             <StaticImage 
                    src="../../../images/brain-tablet.jpg"
                    placeholder="blurred"
                    layout="fullWidth"
                />
{/*                 
                <ImageItem 
            memberName={<>Urban Flat <br />In Santa Rosa</>}
            listingLink="https://www.airbnb.com/rooms/29578859?source_impression_id=p3_1624919097_FkS5aJ0qre7KLuzS"
            
        /> */}
            </ImageGridItem>     
            
        </Box>
        
   
        </Carousel>
        </Box>
    )
}

export default PropertiesCarousel