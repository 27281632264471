import React from 'react'
import {
    Box,
    Text,
    Heading,
    Link,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { ChevronRightIcon } from '@chakra-ui/icons'

const IconBlock = (props) => {
    return (
        <Box w={{sm: '100%', md: '50%'}} pr={{sm: 0, md: '4rem'}} mb={10}>
            <Box mb="1rem">
                <img src={props.icon} alt="service icon" style={{maxWidth: '32px'}} />
            </Box>
            <Box>
                <Heading color="#2B2B2B" mb="1rem" fontSize="1.5rem">
                    {props.heading}
                </Heading>
                <Text color="shadow.600" mb="1rem" >
                    {props.text}
                </Text>
                <Link as={GatsbyLink} to={props.linkTo} fontWeight="700" color="steel.700">
                    Learn More<ChevronRightIcon />
                </Link>
            </Box>
        </Box>
    )
}

export default IconBlock