import React from 'react'
import {
    Button,
    Link, 
    textDecoration
} from '@chakra-ui/react'
import {Link as GatsbyLink} from 'gatsby'

const CtaButton = (props) => {
    return (
        <>
        <Link as={GatsbyLink} to={props.btnLink} target={props.target} rel={props.rel} _hover={{textDecoration: 'none'}}>
            <Button
                variant={props.variant}
            >
                {props.btnText}
            </Button>
        </Link>
        </>
    )
}

export default CtaButton