import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Box, Flex, Tabs, TabList, Tab, TabPanels, TabPanel, Heading, Text, Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon } from "@chakra-ui/react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CtaButton from "../../CtaButton/CtaButton"

const BestInClassSolutionsTabs = () => {

  const data = useStaticQuery(graphql`
  {
    allPrismicHomepageContent {
      edges {
        node {
          data {
            solutions_tabs {
              tab_title {
                text
              }
              tab_text {
                text
              }
              tab_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: AUTO)
                  }
                }
              }
              cta_button {
                url
              }
            }
          }
        }
      }
    }
  }
  `)

  const solutionsTabs = data.allPrismicHomepageContent.edges[0].node.data.solutions_tabs

  return (
    <Box>
      <Tabs orientation="vertical" className="vertical-tabs" display={{sm: 'none', md: 'flex'}}>
        <TabList >

          {solutionsTabs.map((tabContent) => {
            return (
              <Tab bg="shadow.100" className="vertical-tab">
                <Heading color="#2B2B2B" mb="1rem" fontSize="1.5rem">
                  {tabContent.tab_title.text}
                </Heading>
                <Text color="shadow.600" mb="1rem" textAlign="left">
                  {tabContent.tab_text.text}
                </Text>
              </Tab>
            )
          })}

        </TabList>

        <TabPanels minHeight="100%">
          {solutionsTabs.map((tabContent) => {
              return (
                <TabPanel minHeight="100%" display="flex" flexDirection="column">
                  <Flex alignItems="center" flexDirection="column" flex="1" boxShadow="base" rounded="xl" bg="white" p="6" justifyContent="center">
                    <Box w="100%">
                      <Box>
                        <GatsbyImage image={getImage(tabContent.tab_image.localFile)} alt=""/>
                      </Box>
                    </Box>
                  </Flex>
                  
                  <Box textAlign="center" mt="4"><CtaButton btnLink="/technology" variant="ctaButtonBlue" btnText="Learn More About Our Technology" /></Box>
                
                </TabPanel>
              )
            })}
        </TabPanels>
      </Tabs>
      
  <Box display={{sm: 'block', md: 'none'}}>
   <Accordion allowToggle>
        {solutionsTabs.map((tabContent) => {
    return (
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
        {tabContent.tab_title.text}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
    {tabContent.tab_text.text}
    </AccordionPanel>
  </AccordionItem>
    )})
    }
    </Accordion>
    </Box>

    </Box>
  )
}

export default BestInClassSolutionsTabs