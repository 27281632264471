import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import {
    Box,
    Container,
    Flex,
    Heading,
    Text,
} from '@chakra-ui/react'
import CtaButton from '../../CtaButton/CtaButton'
import ContactModal from '../../ContactForm/ContactModal'

const IdealTechPartnerBlockMobile = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicHomepageContent {
        edges {
          node {
            data {
              ideal_technology_partner_block {
                big_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                  alt
                }
                title {
                  text
                }
                content {
                  text
                }
                small_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  `)
  const document = data.allPrismicHomepageContent.edges[0].node.data.ideal_technology_partner_block[0]
    return (
    <Box display={{sm: 'block', md: 'none'}}>
        <Container maxW="container.xl">
        <Box w="100%%">
        <GatsbyImage image={getImage(document.big_image.localFile)} alt={document.big_image.alt} />
            </Box>
        <Box>
                    <Heading as="h2" textAlign="center" my="6">
                        An Ideal Technology Partner
                    </Heading>
                    <Text fontSize="1.5rem" color="steel.700" fontWeight="300">
                        IRIS is an ideal technology partner for early-stage medical device companies without the budget or expertise to invest in developing a new device from the ground up.
                    </Text>
            <Flex flexWrap="wrap" justifyContent="space-around" mb="10">
            <Box>
                <ContactModal />
            </Box>
            <Box my="4" >
                <CtaButton 
                    variant="ctaButtonOutline"
                    btnText="About IRIS Biomedical"
                    btnLink="/about-us"
                />
            </Box>
            </Flex> 
                </Box>
            
        </Container>
        
        <Box>
        <Container maxW="container.xl" mb="4">
                
                <Box>
                    <Box>
                    <GatsbyImage image={getImage(document.small_image.localFile)} alt={document.small_image.alt} />
                    </Box>
                </Box>
        </Container>
        </Box>
    </Box>
    )
}

export default IdealTechPartnerBlockMobile;