import React from 'react'
import {
    Container,
    Box,
    Heading,
    Text
} from '@chakra-ui/react'
import BestInClassSolutionsTabsDynamic from './BestInClassSolutionsTabsDynamic'

const BestInClassSolutionsBlock = (props) => {
    return (
        <Container maxWidth="container.xl" my={20} p={8}>
            <Box w={{sm: '100%', md: '100%'}}>
            <Text variant="sub-head" textAlign="center">
                Best-in-Class Solutions
            </Text>
            <Heading as="h2" fontWeight="600" textAlign="center">
            For Emerging Neuromodulation and Neurodiagnostic Applications
            </Heading>
            </Box>
            <Box my={10}>
                <BestInClassSolutionsTabsDynamic />
            </Box>            
        </Container>
    )
}

export default BestInClassSolutionsBlock