import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react"
import { CheckCircleIcon } from "@chakra-ui/icons"
import { RichText } from "prismic-reactjs"
import { Elements } from "prismic-richtext"


const GreyImageBlock = (props) => {

  const data = useStaticQuery(graphql`
  {
    allPrismicHomepageContent {
      edges {
        node {
          data {
            grey_content_box {
              content {
                raw
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, formats: AUTO, placeholder: BLURRED)
                  }
                }
              }
              title {
                text
              }
            }
          }
        }
      }
    }
  }
`)

const document = data.allPrismicHomepageContent.edges[0].node.data.grey_content_box

const htmlSerializer = function (type, element, content, children, key) {
  switch (type) {
    case Elements.p:
      return <p></p>
    case Elements.list: 
      return <List spacing={3}>
                {children}
              </List>
    
    case Elements.listItem: 
      return <ListItem>
                <ListIcon as={CheckCircleIcon} color="steel.700" style={{marginBottom: '4px'}} />
                  {children}
            </ListItem>
  }
}

  return (
    <Box mb={20}>
      <Container maxWidth="container.xl" bg="shadow.100" p={{sm: '4', md: '20'}}>
        <Flex flexWrap="wrap">
          {document.map((item) => {
            return (
              <>
              <Box w={{ sm: "100%", md: "50%" }} pr={{sm: 0, md: '4rem'}}>
              <GatsbyImage image={getImage(item.image.localFile)} />
          </Box>
          <Box w={{ sm: "100%", md: "50%" }}>
            <Heading as="h2" w={{sm: '100%', md: '75%'}} mb="4" textAlign={{sm: 'center', md: 'left'}}>{item.title.text}</Heading>
            <Text>
              <RichText render={item.content.raw} htmlSerializer={htmlSerializer} />
            </Text>
          </Box>
          </>
            )
          })}
        </Flex>
      </Container>
    </Box>
  )
}

export default GreyImageBlock
