import React from 'react'
import {
    Box,
    Flex,
    Container,
    Heading
} from '@chakra-ui/react'
import IrisBackground from './IrisBackground'
import CtaButton from '../CtaButton/CtaButton'
import ContactModal from '../ContactForm/ContactModal'

const HomeHero = () => {

    return (
      <Box h={{sm: '50vh', md: '60vh'}} className="home-hero" my={{ sm: '4rem', md: 0}}>
      <Flex position="absolute"
        justifyContent="flex-end"
        alignItems="center"
        h="100%"
        width="100%"
      >
        <Box w={{md: '60%', lg: '35%', xl: '45%'}}>
          <IrisBackground />
          </Box>
      </Flex>
      <Flex h="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            position="relative"
            //zIndex="50"
          >
          <Container maxWidth="container.lg">
            <Heading as="h1" fontSize={{ sm: '2rem', md: '3rem'}} my={{sm: '2rem', md: 0}} textAlign="center" fontWeight="600">
              Accelerate Your Path To Market
            </Heading>
            <Heading as="h2" textAlign="center" fontWeight="100" my={{sm: '2rem', md: 0}} fontSize={{ sm: '1.75rem', md: '2.5rem'}} >
            World-Class Medical Design and Manufacturing Services for Emerging Neurotechnology Applications
            </Heading>
            <Flex justifyContent="center" flexWrap="wrap">
            <Box p={{sm: '2', md: '8'}}>
                <ContactModal />
            </Box>
            <Box p={{sm: '2', md: '8'}}>
                <CtaButton 
                    variant="ctaButtonOutline"
                    btnText="Our Services"
                    btnLink="/services"
                />
            </Box>
            </Flex>            
          </Container>
          </Flex>
    </Box>
    )
}

export default HomeHero