import React from "react"

import Layout from '../components/layout'

import Seo from "../components/seo"
import HomeHero from "../components/HomePage/HomeHero"
import PropertiesCarousel from "../components/HomePage/PropertiesCarousel/PropertiesCarousel"
import OurServicesBlock from "../components/OurServicesBlock/IconBlock/OurServicesBlock"
import GreyImageBlock from "../components/GreyImageBlock/GreyImageBlock"
import BestInClassSolutionsBlock from "../components/HomePage/BestInClassSolutionsBlock/BestInClassSolutionsBlock"
import IdealTechPartnerBlock from "../components/HomePage/IdealTechPartnerBlock/IdealTechPartnerBlock"
import IdealTechPartnerBlockMobile from "../components/HomePage/IdealTechPartnerBlock/IdealTechPartnerBlockMobile"

const IndexPage = ({ data }) => {

 return (
    
  <Layout
    annoucementDisplay="block"
    annoucementButton="Annoucement"
  >
  <Seo title="Iris Biomedical | A Ripple Ventures Company" />
    <HomeHero />
    
    <PropertiesCarousel />
    
    <OurServicesBlock />


    <GreyImageBlock 
      title="An Original Design Manufacturer (ODM)"
      text="IRIS designed its core technology platform from the ground up to offer a range of sub-component and complete-system designs. Our designs have been rigorously field tested and can be easily adapted to a variety of form factors. We specialize in:"
      bulletPoint1="High-performance closed-loop neural sensing"
      bulletPoint2="Stimulation"
    />

    <BestInClassSolutionsBlock />

    <IdealTechPartnerBlock />
    <IdealTechPartnerBlockMobile />

  </Layout>
  )
}

// export const query = graphql`
// {
//   allPrismicHomepageContent {
//     data {
//       our_services_section {
//         description {
//           text
//         }
//         icon {
//           url
//         }
//         learn_more_link {
//           slug
//         }
//         title {
//           text
//           type
//         }
//       }
//     }
//   }
// }
// `

export default IndexPage
