import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import {
    Box,
    Container,
    Flex,
    Heading,
    Text,
} from '@chakra-ui/react'
import CtaButton from '../../CtaButton/CtaButton'
import ContactModal from '../../ContactForm/ContactModal'

const IdealTechPartnerBlock = () => {
    const data = useStaticQuery(graphql`
    {
      allPrismicHomepageContent {
        edges {
          node {
            data {
              ideal_technology_partner_block {
                big_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                  alt
                }
                title {
                  text
                }
                content {
                  text
                }
                small_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                  alt
                }
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicHomepageContent.edges[0].node.data.ideal_technology_partner_block[0]
    return (

    <Box display={{sm: 'none', md: 'block'}}>
        <Container maxW="container.xl">
            <Box w="75%">
                <GatsbyImage image={getImage(document.big_image.localFile)} alt={document.big_image.alt} />
            </Box>
        </Container>
        
        <Box bg="shadow.100" mt="-20">
        <Container maxW="container.xl">
        <Flex pt="40"
            justifyContent="space-between"
        >
                <Box w="50%">
                    <Heading as="h2">
                        An Ideal Technology Partner
                    </Heading>
                    <Text fontSize="1.5rem" color="steel.700" fontWeight="300">
                        IRIS is an ideal technology partner for early-stage medical device companies without the budget or expertise to invest in developing a new device from the ground up.
                    </Text>
            <Flex flexWrap="wrap">
            <Box>
                <ContactModal />
            </Box>
            <Box ml={{sm: 0, md: '15'}}>
                <CtaButton 
                    variant="ctaButtonOutline"
                    btnText="About IRIS Biomedical"
                    btnLink="/about-us"
                />
            </Box>
            </Flex> 
                </Box>
                <Box w="40%">
                    <Box position="relative" top="-60">
                        <GatsbyImage image={getImage(document.small_image.localFile)} alt={document.small_image.alt} />
                    </Box>
                </Box>
            </Flex>
        </Container>
        </Box>
    </Box>
)
    }

export default IdealTechPartnerBlock;